<template>
  <div
    v-if="!isNew && type === 'add' && !chapterId"
    class="d-flex fill-height align-center justify-center"
  >
    <v-btn color="primary" width="150">
      <v-icon>mdi-plus</v-icon>
      添加新的章节
    </v-btn>
  </div>
  <div v-else-if="isNew && type === 'edit' && chapterId" class="fill-height">
    <div v-if="httpRequestDone" class="fill-height">
      <div
        v-if="!videoList.length"
        class="d-flex fill-height align-center justify-center"
      >
        <v-btn text color="primary" @click="addVideo">开始添加该章节内容</v-btn>
      </div>
      <v-card v-else flat>
        <v-row class="pa-0 d-flex align-center">
          <v-subheader>视频列表 - {{ videoList.length }}</v-subheader>
          <v-spacer />
          <v-btn text small color="primary" @click="addVideo">
            <v-icon>mdi-plus</v-icon>
            添加
          </v-btn>
        </v-row>
        <v-divider></v-divider>
        <template v-for="(video, index) in videoList">
          <v-card
            :key="`video-${index}`"
            flat
            outlined
            class="rounded mt-3 px-5"
            link
          >
            <v-row>
              <v-col md="6">
                <div class="subtitle-1">{{ video.title }}</div>
                <div class="caption mt-3 blue-grey--text text--darken-1">
                  {{ `扫码量: ` + video.qrcode_scanned_count }}
                </div>
                <div class="mt-3 caption blue-grey--text text--darken-1">
                  <span class="mr-3">创建日期: {{ video.created_at }}</span>
                  <span>修改日期: {{ video.updated_at }}</span>
                </div>
              </v-col>
              <v-col md="3">
                <v-img
                  contain
                  class="shadow qrcode-size rounded"
                  :src="video.qrcode_link"
                  :lazy-src="video.qrcode_link"
                ></v-img>
              </v-col>
              <v-col md="3" class="text-right d-flex align-center justify-end">
                <div>
                  <div>
                    <v-btn
                      small
                      text
                      color="primary"
                      @click="editVideo(video.id)"
                    >
                      编辑
                    </v-btn>
                  </div>
                  <div>
                    <v-dialog
                      v-model="confirmDeletePopup"
                      persistent
                      max-width="260"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          text
                          color="error"
                          v-bind="attrs"
                          v-on="on"
                        >
                          删除
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="subtitle-1 red--text">
                          是否确认删除
                          <span class="text-truncate">{{ video.name }}</span>
                        </v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="default darken-1"
                            text
                            @click="confirmDeletePopup = false"
                          >
                            取消
                          </v-btn>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="deleteVideo(video.id)"
                          >
                            确认
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                  <div><v-btn small text color="teal" @click="downloadQrCode(video)">下载</v-btn></div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-card>
    </div>
    <div v-else>
      <v-skeleton-loader
        type="list-item-avatar-three-line@3"
      ></v-skeleton-loader>
    </div>
  </div>
  <div v-else class="d-flex fill-height align-center justify-center">
    <v-btn text color="primary" disabled>请选择章节或增加新的章节</v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VideoService from "@/services/video.service";

export default {
  name: "List",

  data() {
    return {
      isNew: 1,
      type: "add",
      chapterId: null,
      httpRequestDone: false,
      confirmDeletePopup: false
    };
  },

  watch: {
    "$route.query": function(to) {
      this.initList(to);
    },
    chapterId: function(to) {
      this.httpRequestDone = false;
      if (to && this.type === "edit") {
        this.setChapterVideos({ id: to, params: {} })
          .then(() => {
            this.httpRequestDone = true;
          })
          .catch(e => {
            console.log(e);
          });
      }
    }
  },

  computed: {
    ...mapGetters("chapter", {
      videoList: "getVideos",
      error: "getError"
    })
  },

  created() {
    this.initList(this.$route.query);
  },

  methods: {
    downloadQrCode(vid) {
      if (vid.qrcode_link && vid.qrcode_link !== "") {
        window.open(vid.qrcode_link, "_blank");
      }
    },

    deleteVideo(vid) {
      VideoService.deleteVideo(vid);
    },

    initList(query) {
      const { isNew, type, id } = query;
      this.isNew = parseInt(isNew);
      this.type = type;
      this.chapterId = id;
    },

    editVideo(id) {
      const query = this.$route.query;

      this.$router.push({
        path: "/project/single/video",
        query: Object.assign({ vid: id }, query)
      });
    },

    addVideo() {
      this.$router.push({
        path: "/project/single/video",
        query: Object.assign({ isNewVideo: 1 }, this.$route.query)
      });
    },

    ...mapActions("chapter", ["setChapterVideos"])
  }
};
</script>

<style scoped lang="scss">
.qrcode-size {
  width: 100px;
  height: 100px;
}
</style>
